<!--
 * @Author: lxiang
 * @Date: 2023-04-23 08:47:02
 * @LastEditors: lxiang
 * @LastEditTime: 2023-07-13 17:13:33
 * @description: 物理碰撞
 * @FilePath: \sea_mobile\src\views\threejs\demo\Bc.vue
-->
<template>
  <div class="info">
    <Header :title="title" transparent :nav="true" :defaultNav="true" />
    <div class="box" ref="box"></div>
  </div>
</template>

<script setup>
import Header from "@/components/header/Header.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
// 导入threejs
import * as THREE from "three";
// 导入轨道控制器
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as CANNON from "cannon-es";

const route = useRoute();
const title = route?.query?.title;
const box = ref(null);

const draw = () => {
  // 1、创建场景
  const scene = new THREE.Scene();
  // 2、创建相机
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    300
  );
  // 设置相机位置
  camera.position.set(0, 0, 18);
  scene.add(camera);

  const cubeArr = []; // 存储立方体
  const cubeWorldMaterial = new CANNON.Material("cube"); //设置物体材质

  function createCube() {
    // 创建立方体和平面
    const cubeGeometry = new THREE.BoxGeometry(1, 1, 1);
    const cubeMaterial = new THREE.MeshStandardMaterial();
    const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
    cube.castShadow = true;
    scene.add(cube);
    // 创建物理cube形状
    const cubeShape = new CANNON.Box(new CANNON.Vec3(0.5, 0.5, 0.5));

    // 创建物理世界的物体
    const cubeBody = new CANNON.Body({
      shape: cubeShape,
      position: new CANNON.Vec3(0, 0, 0),
      //   小球质量
      mass: 1,
      //   物体材质
      material: cubeWorldMaterial,
    });
    cubeBody.applyLocalForce(
      new CANNON.Vec3(300, 0, 0), //添加的力的大小和方向
      new CANNON.Vec3(0, 0, 0) //施加的力所在的位置
    ); // 添加作用力

    // 将物体添加至物理世界
    world.addBody(cubeBody);
    // 添加监听碰撞事件
    function HitEvent(e) {
      // 获取碰撞的强度
      const impactStrength = e.contact.getImpactVelocityAlongNormal(); //获取碰撞的强度
      if (impactStrength > 2) {
        hitSound.currentTime = 0; // 重新从零开始播放
        hitSound.volume = impactStrength / 12; // 设置音量
        // hitSound.play();
      }
    }
    cubeBody.addEventListener("collide", HitEvent);
    cubeArr.push({
      mesh: cube,
      body: cubeBody,
    }); // 将立方体添加到数组中
  }

  window.addEventListener("click", createCube);

  const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(20, 20),
    new THREE.MeshStandardMaterial()
  ); // 创建地面

  floor.position.set(0, -5, 0);
  floor.rotation.x = -Math.PI / 2;
  floor.receiveShadow = true;
  scene.add(floor);

  // 创建物理世界
  // const world = new CANNON.World({ gravity: 9.8 });
  const world = new CANNON.World();
  world.gravity.set(0, -9.8, 0);

  // 创建击打声音
  const hitSound = new Audio("assets/metalHit.mp3");

  // 物理世界创建地面
  const floorShape = new CANNON.Plane();
  const floorBody = new CANNON.Body();
  const floorMaterial = new CANNON.Material("floor");
  floorBody.material = floorMaterial;
  // 当质量为0的时候，可以使得物体保持不动
  floorBody.mass = 0;
  floorBody.addShape(floorShape);
  // 地面位置
  floorBody.position.set(0, -5, 0);
  // 旋转地面的位置
  floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
  world.addBody(floorBody);

  // 设置2种材质碰撞的参数
  const defaultContactMaterial = new CANNON.ContactMaterial(
    cubeWorldMaterial,
    floorMaterial,
    {
      // 摩擦力
      friction: 0.1,
      // 弹性
      restitution: 0.5,
    }
  );

  // 讲材料的关联设置添加的物理世界
  world.addContactMaterial(defaultContactMaterial);

  // 设置世界碰撞的默认材料，如果材料没有设置，都用这个
  world.defaultContactMaterial = defaultContactMaterial;

  //添加环境光和平行光
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
  scene.add(ambientLight);
  const dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
  dirLight.castShadow = true;
  scene.add(dirLight);

  // 初始化渲染器
  // 渲染器透明
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  // 设置渲染的尺寸大小
  renderer.setSize(box.value.offsetWidth, box.value.offsetHeight);
  // 开启场景中的阴影贴图
  renderer.shadowMap.enabled = true;

  // 将webgl渲染的canvas内容添加到body
  box.value.appendChild(renderer.domElement);

  // 创建轨道控制器
  const controls = new OrbitControls(camera, renderer.domElement);
  // 设置控制器阻尼，让控制器更有真实效果,必须在动画循环里调用.update()。
  controls.enableDamping = true;

  // 添加坐标轴辅助器
  const axesHelper = new THREE.AxesHelper(5);
  scene.add(axesHelper);
  // 设置时钟
  const clock = new THREE.Clock();

  function render() {
    let deltaTime = clock.getDelta();
    // 更新物理引擎里世界的物体
    world.step(1 / 120, deltaTime);

    cubeArr.forEach((item) => {
      item.mesh.position.copy(item.body.position);
      // 设置渲染的物体跟随物理的物体旋转
      item.mesh.quaternion.copy(item.body.quaternion);
    });

    renderer.render(scene, camera);
    //   渲染下一帧的时候就会调用render函数
    requestAnimationFrame(render);
  }

  render();

  // 监听画面变化，更新渲染画面
  window.addEventListener("resize", () => {
    // 更新摄像头
    camera.aspect = window.innerWidth / window.innerHeight;
    //   更新摄像机的投影矩阵
    camera.updateProjectionMatrix();

    //   更新渲染器
    renderer.setSize(box.value.offsetWidth, box.value.offsetHeight);
    //   设置渲染器的像素比
    renderer.setPixelRatio(window.devicePixelRatio);
  });
};
onMounted(() => {
  draw();
});
</script>
<style lang="less" scoped>
.info {
  padding-top: var(--nav-bar-height);
  background: #e5e5e530;
  height: 100%;
  .box {
    height: 100%;
    background: #3a5b46;
  }
}
</style>
